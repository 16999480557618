import React from 'react';

import { db } from '../db';

export const Teacher= ({ teacherName }) => {
    const curTeacher = db.teachers.find(teacher => teacher.key === teacherName);

    return (
        <div className="container">
            <div className="title-holder mt-3">
                <h1 style={{fontSize: window.outerWidth > 500 ? 50 : 40}}>{curTeacher.position + ' ' + curTeacher.name}</h1>
            </div>
            <img
                src={`../teachers/${curTeacher.imgURL}`}
                className="image-big mt-3"
                alt="..."
                style={{padding: 15}}
            />
            <h2 className="mt-4 price">Цена: {curTeacher.price}</h2>
            <h2 className="mt-4">Личная информация</h2>
            <p>
                <span className="font-weight-bold">Дата рождения: </span>{curTeacher.birthdate}<br/>
                <span className="font-weight-bold">Пол: </span>{curTeacher.sex}<br/>
                <span className="font-weight-bold">Гражданство: </span>{curTeacher.citizenship}<br/>
            </p>
            <h2 className="mt-4">Опыт работы</h2>
            <ul>
                {curTeacher.experience.map(exp => (
                    <li key={curTeacher.experience.indexOf(exp)}>{exp}</li>
                ))}
            </ul>
            <h2 className="mt-4">Достижения</h2>
            <ul>
                {curTeacher.achievements.map(achievement => (
                    <li key={curTeacher.achievements.indexOf(achievement)}>{achievement}</li>
                ))}
            </ul>
            <h2 className="mt-4">Образование</h2>
            <ul>
                {curTeacher.education.map(educ => (
                    <li key={curTeacher.education.indexOf(educ)}>{educ}</li>
                ))}
            </ul>
            <h2 className="mt-4">Личные качества</h2>
            <ul>
                {curTeacher.personal.map(quality => (
                    <li key={curTeacher.personal.indexOf(quality)}>{quality}</li>
                ))}
            </ul>
        </div>
    );
};

