import React from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { CarouselBuilded } from '../components/CarouselBuilded';
import { ReasonList } from '../components/ReasonList';

export const Home = () => {
    return(
        <div className="container-sm pt-4">
            <Card className="home-card">
                <Card.Body style={{padding: window.outerWidth > 500 ? 40 : 10 }}>
                    <div className="carousel-holder mt-1">
                        <h1 className="carousel-title" style={{fontSize: window.outerWidth > 500 ? 40 : 30 }}>Направления нашей работы:</h1>
                    </div>
                    <CarouselBuilded className="centered" />
                </Card.Body>
            </Card>
            <Card className="home-card">
                <Card.Body style={{padding: window.outerWidth > 500 ? 40 : 10 }}>
                    <ReasonList />
                </Card.Body>
            </Card>
            <Card className="home-card">
                <Card.Body style={{padding: window.outerWidth > 500 ? 40 : 10 }}>
                    <Link to="/contacts">
                        <img style={{ width: '100%', borderRadius: '0.25rem' }} src="./banners/contacts-link.jpg" alt="..." />
                    </Link>
                </Card.Body>
            </Card>
            {
                //<Card className="home-card">
                //    <Card.Body>
                //        <Link to="/articles">
                //            <img style={{ width: '100%', borderRadius: '0.25rem' }} src="./banners/articles-link.jpg" alt="..." />
                //        </Link>
                //    </Card.Body>
                //</Card>
            }
        </div>
    )
}

