import React from 'react';

import { db } from '../db';
import { TeacherCard } from './TeacherCard';

export const TeachersContainer = () => {
    return(
        <div className="centered goods-container d-flex">
            <div className="row goods-container no-gutters justify-content-center">
                {
                    db.teachers.map(teacher => <TeacherCard teacher={teacher} key={teacher.key}/>)
                }
            </div>
        </div>
    )
    
}