import React from 'react';
import { Contacts } from '../components/Contacts';

export const ContactsPage = () => {
    return (
        <div className="container pt-4">
            <div className="title-holder mt-3">
                <center>
                    <h1>Как с нами связаться?</h1>
                </center>
            </div>
            <Contacts />
        </div>
    )
}