import React from 'react';
import { Card } from 'react-bootstrap';

export const Contacts = () => {
    return (
        <div style={{fontSize: window.outerWidth > 500 ? 30 : 22}}>
            <p><span style={{fontWeight: 500}}>По телефону:</span> +7(995)895-02-98 / +7(916)630-73-98<br/>
            <span style={{fontWeight: 500}}>По электронной почте:</span> obitel.matematiki@yandex.ru<br/>
            <span style={{fontWeight: 500}}>При помощи мессенджеров:</span></p>
            <div className="centered goods-container d-flex">
                <div className="row goods-container no-gutters justify-content-center">
                    <Card className="messager-card" style={{ width: window.outerWidth > 500 ? 350 : 200, minWidth: window.outerWidth > 500 ? 350 : 150}}>
                        <Card.Body className="messager-card-body">
                            <a href="https://vk.com/im?sel=352882173" className="messager-title">
                                <img style={{ width: window.outerWidth > 500 ? 300 : 150, borderRadius: '0.25rem' }} src="./messanger/vk.jpg" alt="..."/>
                                <p className="messager-title">Вконтакте</p>
                            </a>
                        </Card.Body>
                    </Card>
                    <Card className="messager-card" style={{ width: window.outerWidth > 500 ? 350 : 200, minWidth: window.outerWidth > 500 ? 350 : 150}}>
                        <Card.Body className="messager-card-body">
                            <a href="https://wa.me/79958950298?text=" className="messager-title">
                                <img style={{ width: window.outerWidth > 500 ? 300 : 150, borderRadius: '0.25rem' }} src="./messanger/wa.jpg" alt="..."/>
                                <p className="messager-title">WhatsApp</p>
                            </a>
                        </Card.Body>
                    </Card>
                    <Card className="messager-card" style={{ width: window.outerWidth > 500 ? 350 : 200, minWidth: window.outerWidth > 500 ? 350 : 150}}>
                        <Card.Body className="messager-card-body">
                            <a href="https://t.me/timofey_amelin" className="messager-title">
                                <img style={{ width: window.outerWidth > 500 ? 300 : 150, borderRadius: '0.25rem' }} src="./messanger/tg.jpg" alt="..."/>
                                <p className="messager-title">Telegram</p>
                            </a>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </div>
    )
}