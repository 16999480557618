import React from 'react';
import { NavLink } from 'react-router-dom'

export const Navbar = () => {
    const navStyle = {
        backgroundColor : '#ed0000'
    }

    return(
        <nav className="navbar navbar-dark navbar-expand-lg" style={navStyle}>
            <NavLink to="/">
                <img 
                    src={window.location.pathname.toString().slice(1).indexOf('/') === -1 ? "./logo.png" : "../logo.png"}
                    style={{width: '64px', height: '64px', marginRight: '10px'}}
                    alt="Обитель Математики"
                />
            </NavLink>
            <div className="navbar-brand">
                <h3 className="logo" style={{ fontSize: window.outerWidth > 500 ? 40 : 20 }}>Обитель Математики</h3>
            </div>
            <ul className="navbar-nav">
                <li className="nav-item">
                    <NavLink className="nav-link"to="/" exact>
                        Главная 
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink className="nav-link" to="/our-team">
                        Наша команда
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink className="nav-link"to="/articles">
                        Наши статьи 
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink className="nav-link"to="/contacts">
                        Как с нами связаться?
                    </NavLink>
                </li>
            </ul>
        </nav>
    )
}