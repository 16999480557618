import React from 'react';
import { TeachersContainer } from '../components/TeachersContainer.js';

export const OurTeam = () => {
    return (
        <div className="container container-big pt-4">
            <div className="title-holder mt-3">
                <center>
                    <h1>Наша команда</h1>
                </center>
            </div>
            <TeachersContainer/>
        </div>
    );
};