import React from 'react';
import { Article } from '../components/Article';

export const ArticlePage = () => {
    const articleKey = window.location.pathname.toString().slice(9);

    return (
        <div className="container container-sm pt-4 mb-5">
            <Article articleKey={articleKey} />
        </div>
    );
};