import React from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { db } from '../db';

export const CoursesContainer = () => {
    return (
        <div className="centered goods-container">
            {
                db.articles.map(article => (
                    <Card key={article.key} className="article-card">
                        <Card.Body>
                            <Card.Title>
                                <Link to={`/article/${article.key}`} className="card-title">{article.title}</Link>
                            </Card.Title>
                            <div className="text-holder">
                                <Card.Text className="card-price">
                                    {article.text[0].slice(0, 185) + '...'}
                                </Card.Text>
                            </div>
                        </Card.Body>
                    </Card>
                ))
            }
        </div>
    );
};