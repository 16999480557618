import React from 'react';
import {Route, Switch, BrowserRouter} from 'react-router-dom';
import { Navbar } from './components/Navbar';
import { Home } from './pages/Home';
import { OurTeam } from './pages/OurTeam';
import { ContactsPage } from './pages/ContactsPage';
import { OurCourses } from './pages/OurCourses';
import { TeacherPage } from './pages/TeacherPage';
import { ArticlePage } from './pages/ArticlePage';

function App() {
  return (
    <div className="App mb-5">
      <BrowserRouter>
        <Navbar />
          <Switch>
            <Route path={'/'} exact component={Home} />
            <Route path={'/our-team'} exact component={OurTeam} />
            <Route path={'/articles'} exact component={OurCourses} />
            <Route path={'/contacts'} exact component={ContactsPage} />
          </Switch>
            <Route path={'/teacher/:name'} exact component={TeacherPage} />
            <Route path={'/article/:key'} exact component={ArticlePage} />
      </BrowserRouter>
    </div>
  );
}

export default App;
