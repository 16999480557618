import React from 'react';

export const ReasonList = () => (
    <div>
        <div className="reasons-holder">
            <h1 className="reasons-title" style={{fontSize: window.outerWidth > 500 ? 40 : 30 }}>10 причин, из-за которых вы захотите обучаться у нас:</h1>
        </div>
        <ol className="reasons-list" style={{fontSize: window.outerWidth > 500 ? 20 : 15 }}>
            <li>
                Применение различных технологий для максимальной результативности процесса обучения;
            </li>
            <li>
                Постоянно повышающие свою квалификацию преподаватели;
            </li>
            <li>
                Проверенные и доказавшие эффективность авторские программы;
            </li>
            <li>
                Отработанная система тестов, контрольных срезов и домашних заданий по всем направлениям;
            </li>
            <li>
                Предоставление всех необходимых учебных материалов;
            </li>
            <li>
                Возможность как дистанционной, так и очной формы занятий или их сочетания;
            </li>
            <li>
                Увлекательная, разнообразная и доступная форма подачи материала;
            </li>
            <li>
                Мы знаем требования, предъявляемые на экзаменах и постоянно отслеживаем все изменения;
            </li>
            <li>
                Каждый год наши ученики успешно сдают экзамены и поступают в ВУЗы на бюджетные места;
            </li>
            <li>
                Постоянная связь с родителями, возможность отслеживать процесс и текущие результаты.
            </li>
        </ol>
    </div>
)