import React from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export const TeacherCard = ({ teacher }) => (
    <Card key={teacher.key} style={{ width: '12rem' }} className="card">
        <div className="card-image-holder">
            <img src={`./teachers/${teacher.imgURL}`} className="image" alt={teacher.position + ' ' + teacher.name} />
        </div>
        <Card.Body>
            <Card.Title>
                <Link style={{fontWeight: 700}} to={`/teacher/${teacher.key}`} className="card-title">
                    <h2>{teacher.position + ' ' + teacher.name}</h2>
                </Link>
            </Card.Title>
            <div className="text-holder">
                <Card.Text>
                    <p style={{color: 'red', fontWeight: 500}}>{teacher.price}</p>
                    {teacher.description}
                </Card.Text>
            </div>
        </Card.Body>
    </Card>
);