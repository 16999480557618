import React, { useState } from 'react';
import {Carousel} from 'react-bootstrap';

export const CarouselBuilded = () => {
    const navStyle = {
        width: '100%',
        margin: '0 auto'
    };
    const imgStyle = {
        width: '100%',
        margin: '0 auto'
    };

    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };

    return (
        <Carousel 
            activeIndex={index}
            onSelect={handleSelect}
            style = {navStyle}
            className="rounded carousel">
            <Carousel.Item>
                <img
                    className="d-block rounded"
                    src="./banners/exams1.jpg"
                    alt="Second slide"
                    style = {imgStyle}
                />
                <Carousel.Caption>
                    <h1 style={{fontSize: window.outerWidth > 500 ? 50 : 20 }}>Подготовка к ЕГЭ/ОГЭ</h1>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <img
                    className="d-block rounded"
                    src="./banners/olimpiada1.jpg"
                    alt="Third slide"
                    style = {imgStyle}
                />
                <Carousel.Caption>
                    <h1 style={{fontSize: window.outerWidth > 500 ? 50 : 20 }}>Подготовка к олимпиадам</h1>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <img
                    className="d-block rounded"
                    src="./banners/school1.jpg"
                    alt="First slide"
                    style = {imgStyle}
                />
                <Carousel.Caption>
                    <h1 style={{fontSize: window.outerWidth > 500 ? 50 : 20 }}>Помощь по школьной программе</h1>
                </Carousel.Caption>
            </Carousel.Item>
        </Carousel>
    )

}
