import React from 'react';
import { db } from '../db';

export const Article = ({ articleKey }) => {
    const curArticle = db.articles.find(article => article.key === articleKey);

    return (
        <div>
            <div className="title-holder mt-3">
                <h1>{curArticle.title}</h1>
            </div>
            {curArticle.text.map(paragraph => (
                <div key={curArticle.text.indexOf(paragraph)}>
                    <p
                        className="article-text"
                        key={curArticle.text.indexOf(paragraph)}
                    >
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{paragraph}
                    </p>
                    {curArticle.images.map(image => {
                        if (image.after === curArticle.text.indexOf(paragraph)) {
                            return (
                                <img src={image.src} key={image.after} alt="..." style={{width: '100%', margin: 10}} />
                            );
                        } else {
                            return null;
                        };
                    })}
                </div>
            ))}
        </div>
    );
};