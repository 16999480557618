import React from 'react';
import { Teacher } from '../components/Teacher';

export const TeacherPage = () => {
    const teacherName = window.location.pathname.toString().slice(9);

    return (
        <div className="container pt-4">
            <Teacher teacherName={teacherName} />
        </div>
    );
};