import React from 'react';
import { CoursesContainer } from '../components/CoursesContainer';

export const OurCourses = () => {
    return (
        <div className="container pt-4">
            <div className="title-holder mt-3">
                <center>
                    <h1>Наши статьи</h1>
                </center>
            </div>
            <CoursesContainer />
        </div>
    );
};